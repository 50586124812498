.main-content {
    padding-top: 30px;
}
.video-container {
  width: 100vw;
  height: 100vh;
}
.app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set the height of the container to full viewport height */
  }
  .box {
    display: flex;
    align-items: center;
    border: 1px solid black;
    padding: 10px;
  }
  .empty-toolbar {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .label {
    margin-right: 10px;
  }
  .content {
    flex-grow: 1;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
  }
  .input-wrapper > label {
    margin-right: 10px;
  }
  .rectangle {
    width: 100px;
    height: 100px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rectangle img {
    max-width: 80%;
    max-height: 80%;
    align-items: center;
  }
.strip {
  width: 100%;
  max-height: 100px;
}

.strip img {
  width: 50px;
  height: 40px;
  margin-right: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}



.webcam-video {
  position: relative;
  width: 320px;
  height: 300px;
 .video {
    display: none;
    position: absolute;
    top: 300px;
  }
  .button {
    display: none;
    position: absolute;
    z-index: 9;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 40px;
    border: none;
    background: rgba(255, 255, 255, 0.4);
  }
  .video{
    display: none;
    position: absolute;
    top: 300px;
  }
  .canvas {
    position: absolute;
    left: 0;
    bottom: 60px;
  }
  .photo-booth {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    overflow-x: auto;
    .strip {
      display: flex;
    }
  }
}
  .scene {
    display: block;
    position: relative;
    width: 200px;
    height: 200px;
    border: 5px solid black;
    overflow: hidden;
    transition: 1s;
    .clouds {
      position: absolute;
      left: 0;
      top: 50px;
      width: 100%;
    }
    .mountains {
      position: absolute;
      left: 0px;
      bottom: 0px;
      top:0px;
      width: 200px;
    }
  }
