/* CSS for the grid container wrapper */
.grid-container-wrapper {
    display: flex;
    margin-top: 20px;
  }
  
  /* CSS for the grid container */
  .grid-container {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Create two equal columns */
    grid-gap: 16px;
    grid-auto-columns: 1fr;
  }
  .grid-container_bottom {
    display: flex;
    justify-content: space-between;
  }

  .image-container {
    max-width: 300px; /* Ensure the image doesn't exceed its container width */
    max-height: 300px; /* Ensure the image doesn't exceed its container height */
    display: flex;
    align-items: center; /* Vertically center the image */
    justify-content: center; /* Horizontally center the image */
}

.card {
  width: 100%; /* Ensure each card takes the full width of its container */
  height: 200px; /* Ensure the card doesn't exceed the container height */
  overflow: hidden;
  position: relative;

}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  width: 100%; /* Add this line to make sure the image takes the full width */
  height: 100%;
}


.single-image {
  display: flex; /* Use flexbox to center a single image */
  justify-content: center;
  align-items: center;
}

/* CSS for the left grid */
.left-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 16px; /* Gap between grid items */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    border: 1px solid #d8cbcb;
    padding: 8px;
    height: 500px;
    overflow: hidden;
    position: relative;
  }

  /* CSS for each grid item */
  .grid-item {
    border: 1px solid #ccc;
    background-color: white; /*Set background color*/
    width: 100%;
    height: 200px;
  }

  .right-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 16px; /* Gap between grid items */
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    border: 1px solid #ccc;
    padding: 8px;
    margin-right: 10px;
    height: 500px;
  }
  .header_center_align {
    display: flex; /* Make it a flex container */
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  .title {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    max-width: 790px;
    font-size: 30px;
    color: #00448B;
  }
  .title1 {
    text-transform: none;
    padding: 20px;
    text-align: left;
    font-weight: bold;
    font-size: 30px;
    color: #00448B;
  }
  .td_style{
    width: 30px;
    height: 30px;
    text-align: center; /* Center text horizontally */
    vertical-align: middle;
    color: white; /* Center text vertically */
  }
  
  .blue-text {
    background-color: #4472C4; /* Set text color to blue */
  }
  
  .yellow-text {
    background-color: #92d050; /* Set text color to yellow */
  }
  .grid-title {
    font-size: 18px; /* Adjust the font size as needed */
    font-weight: bold;
    color: #333; /* Adjust the text color as needed */
    margin-bottom: 10px; /* Adjust the margin as needed to separate the title from content */
  }
  .left-grid_bottom {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text horizontally */
    margin-top:10px;
    flex-direction: column;
  }.centered-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    height: 100%; /* Ensure the text takes up the full height of the container */
  }
  .left-text {
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 20px;
    height: 100%; /* Ensure the text takes up the full height of the container */
  }

  /* Updated styles for the "Improvement" text */
.right-text {
  display: flex;
  justify-content: flex-end; /* Align text to the right */
  align-items: center;
  font-size: 20px;
  height: 100%;
}
  /* Add a blue box around "Before Kaizen" */
  .box {
    background-color: #00448b;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 10px;
    border-radius: 5px;
    width: 200px;
    height: 50px;
    color: white;
    text-align: center; /* Center text horizontally within the box */
  }
/* Style for the description box */
.box_description {
    background-color: #00448b;
    display: flex;
    flex-direction: column; /* Display children vertically */
    justify-content: center; /* Center horizontally */
    align-items: flex-start; /* Align text to the left */
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    color: white;
    height: 100px;
    width: 100%;
    text-align: center; /* Center text horizontally within the box */
  }
  .box_description_right {
    background-color: #00448b;
    display: flex;
    flex-direction: column; /* Display children vertically */
    justify-content: center; /* Center horizontally */
    align-items: flex-start; /* Align text to the left */
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    width: 100%;
    height: 100px;
    text-align:left; /* Center text horizontally within the box */
  }
  .right-grid_bottom {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
  }
  
  
  
  
  
  
  